<template>
  <div class="manage-project-sales-legal-process">
    <div class="container fluid">
      <legal-client-detail
        :canManage="false"
        :getClientAPI="lawyerAPI.getClient"
        :markWithLADateAPI="lawyerAPI.markWithLA"
        :markWithoutLADateAPI="lawyerAPI.markWithoutLA"
        :setLADateAPI="lawyerAPI.setLADate"
        :setSPADateAPI="lawyerAPI.setSPADate"
        :signLAAPI="lawyerAPI.signLA"
        :signSPAAPI="lawyerAPI.signSPA"
        :payStampLAAPI="lawyerAPI.payStampLA"
        :payStampSPAAPI="lawyerAPI.payStampSPA"
        :downloadFileAPI="downloadFile"
        :isLawyer="false"
      ></legal-client-detail>
    </div>
  </div>
</template>

<script>
import LegalClientDetail from "@/components/GlobalComponents/Shared/Lawyer/LegalClientDetail";
import { projectLegalProcess as lawyerAPI } from "@/api";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    LegalClientDetail
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      lawyerAPI,
      downloadFile
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
